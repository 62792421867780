export default [
    {
        path: '/',
        text: 'Home'
    },
    {
        path: '/about',
        text: 'About'
    },
    {
        path: '/projects',
        text: 'Projects'
    },
    {
        path: '/contact',
        text: 'Contact'
    },
    
]